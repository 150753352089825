import { Games } from "@/utils/dicts";
import { defineStore } from "pinia";
import { useUserInfoStore } from "./userInfo";

export const useUserStatisticsStore = defineStore("userStatistics", () => {
    const { $locally } = useNuxtApp();
    const userInfoStore = useUserInfoStore();

    type GamesAvailable = Games.Age | Games.Wordle | Games.Statistics;

    const open = ref(false);
    const key = "userStats";
    const selectedGame = ref<GamesAvailable>(Games.Wordle);

    const userStatistics = ref<Record<string, any>>({});

    async function createStatistic(game: Games, data: Record<string, any>) {
        const stats = {
            ...userStatistics.value,
            [game]: userStatistics.value[game]
                ? [...userStatistics.value[game], data]
                : [data],
        };

        if (userInfoStore.userInfo) {
            await userInfoStore.updateUserInfo({
                statistics: stats,
            });
        } else {
            $locally.setItem(key, JSON.stringify(stats));
        }

        refresh();
    }

    function refresh() {
        if (userInfoStore.userInfo) {
            userStatistics.value = userInfoStore.userInfo.statistics;
            return;
        }

        userStatistics.value = $locally.getItem(key)
            ? JSON.parse($locally.getItem(key)!)
            : {};
    }

    function haveWordleTodayBeenPlayed() {
        return (
            userStatistics.value[Games.Wordle] &&
            userStatistics.value[Games.Wordle].some((statistic: any) => {
                if (statistic.mode !== "today" || !statistic.date) return false;

                return statistic.date === getAbreviatedDate();
            })
        );
    }

    return {
        open,
        userStatistics,
        selectedGame,

        createStatistic,
        refresh,
        haveWordleTodayBeenPlayed,
    };
});
