export function useAppToast() {
    const toast = useToast();
    const i18n = useI18n();

    function errorToast(options?: {
        title?: string;
        id?: string;
        timeout?: number;
    }) {
        toast.add({
            id: options?.id ?? "errorNotification",
            color: "red",
            title: options?.title ?? i18n.t("error"),
            icon: "i-heroicons-x-circle",
            timeout: options?.timeout,
        });
    }

    function successToast(options?: {
        title?: string;
        id?: string;
        timeout?: number;
    }) {
        toast.add({
            id: options?.id ?? "successNotification",
            title: options?.title ?? i18n.t("success"),
            icon: "i-heroicons-check-badge",
            timeout: options?.timeout,
        });
    }

    return {
        errorToast,
        successToast,
    };
}
