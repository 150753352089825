<template>
    <div
        class="w-full bg-gray-200 rounded-full h-2 overflow-hidden"
        :class="{
            'h-2': size === 'sm',
            'h-3': size === 'md' || !size,
            'h-4': size === 'lg',
        }"
    >
        <div
            class="h-full animate-pulse rounded-full bg-gradient-to-r from-green-500 to-blue-500"
            :style="progressStyle"
        ></div>
    </div>
</template>

<script setup lang="ts">
import { useTimeoutFn } from "@vueuse/core";
import { computed, ref } from "vue";

const props = defineProps<{
    start: number;
    end: number;
    size?: "sm" | "md" | "lg";
}>();

const currentValue = ref(props.start);
const animationTime = ref(1.5); // seconds
const easeType = ref<"in-out" | "in" | "out">("in-out");

const progressStyle = computed(() => ({
    width: `${currentValue.value + 2}%`,
    transition: `width ${animationTime.value}s ease-${easeType.value}`,
}));

const animateToEnd = () => {
    if (props.end < props.start) {
        animationTime.value = 0.75;
        easeType.value = "in";
        currentValue.value = 100;

        useTimeoutFn(() => {
            animationTime.value = 0;
            currentValue.value = 0;

            useTimeoutFn(() => {
                easeType.value = "out";
                animationTime.value = 0.75;

                currentValue.value = props.end;

                useTimeoutFn(() => {
                    easeType.value = "in-out";
                    animationTime.value = 1.5;
                }, animationTime.value * 1000);
            }, 100);
        }, animationTime.value * 1000);
    } else {
        currentValue.value = props.end;
    }
};

watch(
    () => props.end,
    () => useTimeoutFn(animateToEnd, 100),
    { immediate: true }
);
</script>
