import { SupabaseClient } from "@supabase/supabase-js";
import * as iso3311a2 from "iso-3166-1-alpha-2";
import { letterDict, nationalityDict } from "./dicts";
import type { Player } from "./types";

export function useSupabase() {
    const { $supabase: supabase } = useNuxtApp();

    return (supabase as { client: SupabaseClient }).client as SupabaseClient;
}

export function normalizeString(string: string | undefined) {
    if (!string) return "";

    return string
        .split("")
        .map((letter) => (letterDict[letter] ? letterDict[letter] : letter))
        .join("");
}

export function getLastname(name: string) {
    const splitedName = name.match(
        /(([A-zÀ-ú]+)(-[A-zÀ-ú]+)?)\s+(([A-zÀ-ú]+)(-[A-zÀ-ú]+)?(\s[A-zÀ-ú]+)?(\s[A-zÀ-ú]+)?(\s[A-zÀ-ú]+)?)/
    );

    let lastName;

    if (splitedName) {
        lastName = splitedName[4].trim();
    }

    return lastName;
}

export function getNationalityFlagUrl(nationality: string) {
    return `https://media-4.api-sports.io/flags/${
        // @ts-ignore
        iso3311a2.default.getCode(nationality) ?? nationalityDict[nationality]
    }.svg`;
}

export function generateGuessString(player: Player, playerToGuess: Player) {
    let string = "";

    player.league_name === playerToGuess.league_name
        ? (string += "🟩")
        : (string += "🟥");

    player.team_name === playerToGuess.team_name
        ? (string += "🟩")
        : (string += "🟥");

    player.nationality === playerToGuess.nationality
        ? (string += "🟩")
        : (string += "🟥");

    player.position === playerToGuess.position
        ? (string += "🟩")
        : (string += "🟥");

    return string;
}
