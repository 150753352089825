<template>
    <slot />
</template>

<script setup lang="ts">
import { useProgressionStore } from "~/store/progression";
import { useUserStore } from "~/store/user";
import { useUserInfoStore } from "~/store/userInfo";
import { useUserStatisticsStore } from "~/store/userStatistics";

const userStore = useUserStore();
const progressionStore = useProgressionStore();
const userStatisticsStore = useUserStatisticsStore();
const userInfoStore = useUserInfoStore();

onMounted(async () => {
    // ORDER MATTERS
    await userStore.getUser();
    await userInfoStore.setUserInfo();
    progressionStore.init();
    userStatisticsStore.refresh();
});
</script>
