import { defineStore } from "pinia";
import { useUserInfoStore } from "./userInfo";

export const useProgressionStore = defineStore("progression", () => {
    const { $locally } = useNuxtApp();
    const userInfoStore = useUserInfoStore();

    const toast = useToast();

    const isProgressionNotificationRunning = ref(false);
    const progressionNotificationTimeout = ref();

    const points = ref(0);
    const previousCompletion = ref(0);

    const level = computed(() => getProgression(points.value).level);
    const completion = computed(
        () => getProgression(points.value).levelCompletion
    );

    function getProgression(actualPoints: number) {
        let level = 1;
        let requiredPoints = 100; // level 1 required points

        while (actualPoints >= requiredPoints) {
            level++;
            actualPoints -= requiredPoints;
            requiredPoints = Math.floor(requiredPoints * 1.2);
        }

        return {
            level,
            levelRequiredPoints: requiredPoints,
            levelPoints: actualPoints,
            levelCompletion: (actualPoints * 100) / requiredPoints,
        };
    }

    async function addPoints(addedPoints: number) {
        if (addedPoints <= 0) return;

        // Order matter
        previousCompletion.value = completion.value;
        points.value += addedPoints;

        if (userInfoStore.userInfo)
            await userInfoStore.updateUserInfo({ points: points.value });
        else $locally.setItem("points", points.value);

        isProgressionNotificationRunning.value = true;

        toast.add({
            description: "levelProgress",
            icon: "fxemoji:trophy",
            id: "progression",
        });

        if (progressionNotificationTimeout.value)
            clearTimeout(progressionNotificationTimeout.value);

        progressionNotificationTimeout.value = setTimeout(() => {
            isProgressionNotificationRunning.value = false;
        }, 10000);
    }

    function init() {
        if (userInfoStore.userInfo)
            points.value = userInfoStore.userInfo.points;
        else {
            const savedPoints = $locally.getItem("points");

            if (savedPoints == undefined || isNaN(+savedPoints)) {
                points.value = 0;
                return;
            }

            points.value = +savedPoints;
        }
    }

    return {
        points,
        level,
        completion,
        previousCompletion,
        isProgressionNotificationRunning,

        init,
        addPoints,
    };
});
