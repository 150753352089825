import { useProgressionStore } from "~/store/progression";
import { useUserStore } from "~/store/user";
import { useUserInfoStore } from "~/store/userInfo";
import { useUserStatisticsStore } from "~/store/userStatistics";

export function useProfile() {
    const supabase = useSupabase();
    const userStore = useUserStore();
    const userInfoStore = useUserInfoStore();
    const progressionStore = useProgressionStore();
    const userStatistics = useUserStatisticsStore();
    const appToast = useAppToast();
    const i18n = useI18n();
    const { public: env } = useRuntimeConfig();

    const loading = ref(false);
    const editing = ref(false);
    const editedUsername = ref("");

    async function sign({ credential }: { credential: string }) {
        loading.value = true;

        const { data, error } = await supabase.auth.signInWithIdToken({
            provider: "google",
            token: credential,
        });

        if (error) {
            appToast.errorToast();

            loading.value = false;

            return;
        }

        if (data.user) {
            await userStore.getUser();

            await userInfoStore.createUserInfo();
            userStatistics.refresh();
            progressionStore.init();

            appToast.successToast({
                title: i18n.t("you-are-logged-in"),
            });
        }

        loading.value = false;
    }

    async function disconnect() {
        await supabase.auth.signOut();

        userStore.setUser(undefined);
        userInfoStore.clearUserInfo();
        userStatistics.refresh();
        progressionStore.init();

        appToast.successToast({
            title: i18n.t("you-have-been-successfully-logged-out"),
        });
    }

    function editUsername() {
        if (!userInfoStore.userInfo) return;

        editedUsername.value = userInfoStore.userInfo.username;

        editing.value = true;
    }

    async function saveUsername() {
        if (!userInfoStore.userInfo) {
            appToast.errorToast();
            return;
        }

        if (editedUsername.value.length < 3) {
            appToast.errorToast({
                title: i18n.t(
                    "the-username-must-be-at-least-3-characters-long"
                ),
            });

            return;
        }

        const { error } = await supabase
            .from("user_infos")
            .update({
                username: editedUsername.value,
            })
            .eq("id", userInfoStore.userInfo.id);

        if (!error) {
            appToast.successToast({
                title: i18n.t("the-username-has-been-successfully-saved"),
                timeout: 3000,
            });
            await userInfoStore.setUserInfo();
            editing.value = false;
        }
    }

    async function updateProfilePicture(file?: File) {
        if (!file || !userStore.user) return;

        const { data, error } = await supabase.storage
            .from("profile_pictures")
            .upload(Math.random().toString(), file);

        if (error) {
            appToast.errorToast();
            return;
        }

        const { error: insertError } = await supabase
            .from("user_infos")
            .update({
                photo_url: `${env.supabase.url}/storage/v1/object/public/${data.fullPath}`,
            })
            .eq("user_id", userStore.user.id);

        if (insertError) {
            appToast.errorToast();
            return;
        }

        appToast.successToast({
            title: i18n.t("the-profile-picture-has-been-successfully-updated"),
        });

        await userInfoStore.setUserInfo();
    }

    return {
        disconnect,
        sign,
        loading,

        saveUsername,
        editUsername,
        updateProfilePicture,
        editing,
        editedUsername,
    };
}
