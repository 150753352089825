import { default as blur6RTBuMzKX7Meta } from "/vercel/path0/pages/admin/blur.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as admin4FCB2UHG0ZMeta } from "/vercel/path0/pages/admin.vue?macro=true";
import { default as ageRIAI5izGR8Meta } from "/vercel/path0/pages/age.vue?macro=true";
import { default as indextBkqoi4sLsMeta } from "/vercel/path0/pages/blur/index.vue?macro=true";
import { default as logo6iN0jNQIjDMeta } from "/vercel/path0/pages/blur/logo.vue?macro=true";
import { default as blurLs441GLg6UMeta } from "/vercel/path0/pages/blur.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as easytJgbQzTILpMeta } from "/vercel/path0/pages/pixels/easy.vue?macro=true";
import { default as hardQCM3PZAoEgMeta } from "/vercel/path0/pages/pixels/hard.vue?macro=true";
import { default as index6RLqDtzdoZMeta } from "/vercel/path0/pages/pixels/index.vue?macro=true";
import { default as pixelsJXWRQLPcLsMeta } from "/vercel/path0/pages/pixels.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as profilekxSCn5bMpQMeta } from "/vercel/path0/pages/profile.vue?macro=true";
import { default as splitEJGd0iJKWRMeta } from "/vercel/path0/pages/split.vue?macro=true";
import { default as stadiumsBGCCJqWVG4Meta } from "/vercel/path0/pages/stadiums.vue?macro=true";
import { default as indexHKoyFrEqetMeta } from "/vercel/path0/pages/statistics/index.vue?macro=true";
import { default as normal5dJMgU8zBaMeta } from "/vercel/path0/pages/statistics/normal.vue?macro=true";
import { default as rankedfp7trt6CePMeta } from "/vercel/path0/pages/statistics/ranked.vue?macro=true";
import { default as statisticsGtaEk5IYuSMeta } from "/vercel/path0/pages/statistics.vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
import { default as indexEjwEad5bpsMeta } from "/vercel/path0/pages/tictactoe/index.vue?macro=true";
import { default as multiplayer1DcwQVVupHMeta } from "/vercel/path0/pages/tictactoe/multiplayer.vue?macro=true";
import { default as solofhnnh7FEzbMeta } from "/vercel/path0/pages/tictactoe/solo.vue?macro=true";
import { default as tictactoeGWwwHnKaDvMeta } from "/vercel/path0/pages/tictactoe.vue?macro=true";
import { default as indexh5X3aYiPkkMeta } from "/vercel/path0/pages/wordle/index.vue?macro=true";
import { default as multiplayere4zJgyv46vMeta } from "/vercel/path0/pages/wordle/multiplayer.vue?macro=true";
import { default as soloXGyO8wf5rvMeta } from "/vercel/path0/pages/wordle/solo.vue?macro=true";
import { default as todayIHuKaVGBCfMeta } from "/vercel/path0/pages/wordle/today.vue?macro=true";
import { default as wordleYnlT02ANyaMeta } from "/vercel/path0/pages/wordle.vue?macro=true";
export default [
  {
    name: admin4FCB2UHG0ZMeta?.name,
    path: "/admin",
    meta: admin4FCB2UHG0ZMeta || {},
    component: () => import("/vercel/path0/pages/admin.vue"),
    children: [
  {
    name: "admin-blur",
    path: "blur",
    meta: blur6RTBuMzKX7Meta || {},
    component: () => import("/vercel/path0/pages/admin/blur.vue")
  },
  {
    name: "admin",
    path: "",
    component: () => import("/vercel/path0/pages/admin/index.vue")
  }
]
  },
  {
    name: "age",
    path: "/age",
    meta: ageRIAI5izGR8Meta || {},
    component: () => import("/vercel/path0/pages/age.vue")
  },
  {
    name: blurLs441GLg6UMeta?.name,
    path: "/blur",
    meta: blurLs441GLg6UMeta || {},
    component: () => import("/vercel/path0/pages/blur.vue"),
    children: [
  {
    name: "blur",
    path: "",
    component: () => import("/vercel/path0/pages/blur/index.vue")
  },
  {
    name: "blur-logo",
    path: "logo",
    component: () => import("/vercel/path0/pages/blur/logo.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactJnsDpYVejzMeta || {},
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: pixelsJXWRQLPcLsMeta?.name,
    path: "/pixels",
    meta: pixelsJXWRQLPcLsMeta || {},
    component: () => import("/vercel/path0/pages/pixels.vue"),
    children: [
  {
    name: "pixels-easy",
    path: "easy",
    meta: easytJgbQzTILpMeta || {},
    component: () => import("/vercel/path0/pages/pixels/easy.vue")
  },
  {
    name: "pixels-hard",
    path: "hard",
    meta: hardQCM3PZAoEgMeta || {},
    component: () => import("/vercel/path0/pages/pixels/hard.vue")
  },
  {
    name: "pixels",
    path: "",
    component: () => import("/vercel/path0/pages/pixels/index.vue")
  }
]
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacykJ3SQnmrN4Meta || {},
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilekxSCn5bMpQMeta || {},
    component: () => import("/vercel/path0/pages/profile.vue")
  },
  {
    name: "split",
    path: "/split",
    meta: splitEJGd0iJKWRMeta || {},
    component: () => import("/vercel/path0/pages/split.vue")
  },
  {
    name: "stadiums",
    path: "/stadiums",
    meta: stadiumsBGCCJqWVG4Meta || {},
    component: () => import("/vercel/path0/pages/stadiums.vue")
  },
  {
    name: statisticsGtaEk5IYuSMeta?.name,
    path: "/statistics",
    meta: statisticsGtaEk5IYuSMeta || {},
    component: () => import("/vercel/path0/pages/statistics.vue"),
    children: [
  {
    name: "statistics",
    path: "",
    component: () => import("/vercel/path0/pages/statistics/index.vue")
  },
  {
    name: "statistics-normal",
    path: "normal",
    component: () => import("/vercel/path0/pages/statistics/normal.vue")
  },
  {
    name: "statistics-ranked",
    path: "ranked",
    meta: rankedfp7trt6CePMeta || {},
    component: () => import("/vercel/path0/pages/statistics/ranked.vue")
  }
]
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsq5qlZHSSonMeta || {},
    component: () => import("/vercel/path0/pages/terms.vue")
  },
  {
    name: tictactoeGWwwHnKaDvMeta?.name,
    path: "/tictactoe",
    meta: tictactoeGWwwHnKaDvMeta || {},
    component: () => import("/vercel/path0/pages/tictactoe.vue"),
    children: [
  {
    name: "tictactoe",
    path: "",
    component: () => import("/vercel/path0/pages/tictactoe/index.vue")
  },
  {
    name: "tictactoe-multiplayer",
    path: "multiplayer",
    component: () => import("/vercel/path0/pages/tictactoe/multiplayer.vue")
  },
  {
    name: "tictactoe-solo",
    path: "solo",
    component: () => import("/vercel/path0/pages/tictactoe/solo.vue")
  }
]
  },
  {
    name: wordleYnlT02ANyaMeta?.name,
    path: "/wordle",
    meta: wordleYnlT02ANyaMeta || {},
    component: () => import("/vercel/path0/pages/wordle.vue"),
    children: [
  {
    name: "wordle",
    path: "",
    component: () => import("/vercel/path0/pages/wordle/index.vue")
  },
  {
    name: "wordle-multiplayer",
    path: "multiplayer",
    component: () => import("/vercel/path0/pages/wordle/multiplayer.vue")
  },
  {
    name: "wordle-solo",
    path: "solo",
    component: () => import("/vercel/path0/pages/wordle/solo.vue")
  },
  {
    name: "wordle-today",
    path: "today",
    component: () => import("/vercel/path0/pages/wordle/today.vue")
  }
]
  }
]