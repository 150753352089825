import type { CookieConsentConfig } from "vanilla-cookieconsent";
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";

const config: CookieConsentConfig = {
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
        },
        preferencesModal: {
            layout: "box",
        },
    },

    onFirstConsent: ({ cookie }) => {
        // first consent
    },

    onConsent: ({ cookie }) => {
        const { public: env } = useRuntimeConfig();

        if (env.nodeEnv === "development") return;

        if (cookie.categories.includes("analytics")) {
            const { initialize } = useGtag();
            initialize();
        }
    },

    onChange: () => {
        // on change
    },

    categories: {
        necessary: {
            readOnly: true,
            enabled: true,
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^(_ga|_gid)/,
                    },
                ],
            },
            enabled: true,
        },
        ads: {
            enabled: true,
        },
    },

    language: {
        default: "en",

        translations: {
            en: {
                consentModal: {
                    title: "Hello Football Fan, it's cookie time!",
                    description:
                        "Our website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.",
                    acceptAllBtn: "Accept",
                    // acceptNecessaryBtn: "Reject",
                    showPreferencesBtn: "Manage preferences",
                    footer: `<a href="/privacy">Privacy Policy</a><a href="/terms">Terms and Conditions</a>`,
                },
                preferencesModal: {
                    title: "Cookie preferences",
                    acceptAllBtn: "Accept all",
                    savePreferencesBtn: "Save preferences",
                    sections: [
                        {
                            title: "Cookie usage",
                            description:
                                'We use cookies to optimize your experience on our website. Some are essential for the site to function, while others help us analyze your usage and improve our services. For more details, refer to our <a href="/privacy" class="cc__link">privacy policy</a>.',
                        },
                        {
                            title: "Strictly necessary cookies",
                            description:
                                "These cookies are essential for the proper functioning of our website. Without them, our site wouldn't be able to operate as expected, such as remembering your privacy settings or login information.",
                            linkedCategory: "necessary",
                        },
                        {
                            title: "Performance and analytics cookies",
                            description:
                                "These cookies allow us to measure and improve the performance of our website by collecting and reporting information about how you use it. This helps us understand which pages are the most or least popular, and how visitors move around the site.",
                            linkedCategory: "analytics",
                        },
                        {
                            title: "Advertisement and targeting cookies",
                            description:
                                "These cookies are used to deliver ads that are relevant to you. They can also be used to limit the number of times you see an ad and help measure the effectiveness of advertising campaigns. They may also track your browsing across other websites.",
                            linkedCategory: "ads",
                        },
                        {
                            title: "More information",
                            description:
                                'For any queries regarding our cookie policy and your choices, please look at our <a class="cc__link" href="/privacy">Privacy Policy</a>.',
                        },
                    ],
                },
            },
        },
    },
};

export default defineNuxtPlugin(async () => {
    await CookieConsent.run(config);

    return {
        provide: {
            CC: CookieConsent,
        },
    };
});
