import { type User } from "@supabase/supabase-js";
import { defineStore } from "pinia";
import { useSupabase } from "~/utils";

export const useUserStore = defineStore("user", () => {
    const supabase = useSupabase();

    const user = ref<User>();

    async function getUser() {
        const { data } = await supabase.auth.getUser();

        if (data.user) user.value = data.user;
        else user.value = undefined;
    }

    function setUser(userToSet: User | undefined) {
        user.value = userToSet;
    }

    const isConnectionModalOpen = ref(false);
    const connectionModalTitle = ref("");
    const connectionModalDescription = ref("");
    const connectionRedirection = ref<string | undefined>("");

    function openConnectionModal(options: {
        title: string;
        description: string;
        redirectionUrl?: string;
    }) {
        connectionModalTitle.value = options.title;
        connectionModalDescription.value = options.description;
        isConnectionModalOpen.value = true;
        connectionRedirection.value = options.redirectionUrl;
    }

    function closeConnectionModal() {
        isConnectionModalOpen.value = false;
        connectionModalTitle.value = "";
        connectionModalDescription.value = "";
        connectionRedirection.value = "";
    }

    return {
        user,
        getUser,
        setUser,

        openConnectionModal,
        closeConnectionModal,
        isConnectionModalOpen,
        connectionModalTitle,
        connectionModalDescription,
        connectionRedirection,
    };
});
